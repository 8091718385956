//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
    data() {
        return {
            featuredProducts: [],
        };
    },
    props: {
        blok: {
            type: Object,
            default: () => { },
        },
    },
    mounted() {

        let featuredProducts = []
        if (this.displayMenu) {
            if  (this.displayMenu.categories) {
                this.displayMenu.categories.forEach(element => {
                    if (element.name != "Krystalpalooza") {
                        if (element.products) {
                            element.products.forEach(prod => {
                                if (prod.featureditem) {
                                    featuredProducts.push(prod)
                                }
                            })
                        }
                    }
                });
            }
        }

        this.featuredProducts = featuredProducts
    },
    methods: {
        checkProductAvailability(product) {
            if (product.availability) {
                if (!product.availability.always && !product.availability.isdisabled && product.availability.now) {
                    return 'active'
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        // checkCategory(cat) {
        //     if (cat.id === 62962) {
        //         return true
        //     }

        //     return false
        // },
        sup(str) {
            return str.replace(/®/g, '<sup>®</sup>')
        },
        titleCase(str) {
            return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())
        },
        addClass: function (e) {
            this.$refs.pEl.classList.add('hover')
        },
        removeClass: function (e) {
            this.$refs.pEl.classList.remove('hover')
        },
    },
    computed: {
        ...mapState(['defaultMenu', 'menu', 'options', 'location']),
        displayMenu() {
            return this.menu ? this.menu : this.defaultMenu
        },
    },
};
